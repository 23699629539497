import React, { useCallback, useState } from "react";
import FormSection from "../../FormComponents/FormSection";

const consentLeasingProcessingText =
  "Działając w imieniu wnioskodawcy, wyrażam zgodę, aby spółka LeasingPoint Sp. z o.o. z siedzibą we Wrocławiu, przeprowadziła analizę wniosku leasingowego i złożyła w moim imieniu zapytanie do firm leasingowych o możliwość udzielenia leasingu reprezentowanej przeze mnie firmy. Dane te będą przetwarzane w celu zawarcia i wykonania wnioskowanej umowy. W tym w celu dane mogą zostać przekazane do Biura Informacji Kredytowej S. A. oraz Systemu Bankowy Rejestr prowadzonego przez Związek Banków Polskich z siedzibą w Warszawie.";
const consentGDPRText =
  "Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z realizacją mojego zapytania. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem /am poinformowany /a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest LeasingPoint Sp. z o.o. z siedzibą we Wrocławiu przy ul. S. Leszczyńskiego 4/25.";
const consentMarketingText =
  "Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w celu przesyłania informacji handlowej drogą elektroniczną zgodnie z ustawą o świadczeniu usług drogą elektroniczną na podany w tym celu adres email lub numer telefonu komórkowego lub inny kontakt elektroniczny. Podanie danych osobowych jest dobrowolne. Zostałem /am poinformowany /a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem danych jest LeasingPoint Sp. z o.o. z siedzibą we Wrocławiu przy ul. S. Leszczyńskiego 4/25.";
const Checkbox = ({
  text,
  name,
  value,
  handleChange,
  link,
  linkText,
  error,
}) => {
  const [textHidden, setTextHidden] = useState(true);
  const showHideText = useCallback(() => {
    setTextHidden(!textHidden);
  }, [textHidden, setTextHidden]);
  return (

    <div className="column is-full">
      <div>
        <label className="is-size-6" htmlFor="consent-1">
          <div className="columns">
            <div>
              <input
                type="checkbox"
                name={name}
                className="offerform-checkbox"
                id={name}
                checked={value}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div
              className={`offerform-checkbox-div ${
                !textHidden ? "offerform-checkbox-div-full" : null
              }`}
            >
              &nbsp;&nbsp;{text}
              <span
                className="has-text-primary offerform-checkbox-div-link"
                onClick={showHideText}
              >
                {textHidden ? "Rozwiń pełną treść" : "Zwiń"}
              </span>
            </div>
          </div>
        </label>
      </div>
      {!linkText ? (
        <br />
      ) : (
        <>
          <br />
          <div className="columns is-vcentered">
            <a
              href={link}
              className="column has-text-primary has-text-centered"
            >
              {linkText}
            </a>
          </div>
        </>
      )}
      {error !== null ? <div className="help is-danger">{error}</div> : null}
    </div>
  );
};

const ConsentsSection = ({
  consentGDPR,
  consentMarketing,
  consentLeasingProcessing,
  handleOnChange,
  errors,
}) => (
  <FormSection title="Zgody">
    <Checkbox
      text={consentLeasingProcessingText}
      value={consentLeasingProcessing}
      name="consentLeasingProcessing"
      handleChange={handleOnChange}
      error={errors.consentLeasingProcessing}
    />
    <Checkbox
      text={consentGDPRText}
      value={consentGDPR}
      name="consentGDPR"
      link="https://leasingpoint.pl/polityka-prywatnosci/"
      linkText="Zobacz pełną informacje jak będą przetwarzane Twoje dane."
      handleChange={handleOnChange}
      error={errors.consentGDPR}
    />
    <Checkbox
      text={consentMarketingText}
      value={consentMarketing}
      name="consentMarketing"
      handleChange={handleOnChange}
      error={errors.consentMarketing}
    />
  </FormSection>
);

export default ConsentsSection;
