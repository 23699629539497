import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { useFormik } from "formik";
import LeasingItemSection from "./LeasingItemSection";
import LeasingConditionsSection from "./LeasingConditionSection";
import PersonalDataSection from "./PersonalDataSection";
import ConsentsSection from "./ConsentsSection";
import { ServerError } from "../Errors";
import SubmitButton from "../FormComponents/FormSubmitButton";
import { requestOffer, timeoutOnFetch } from "../../api/api";
import { validatePolish } from "validate-polish";
import * as EmailValidator from "email-validator";
import Cars from "../../data/cars.json";

const validate = (values) => {
  const errors = {};
  if (!values.carType) {
    errors.carType =
      "Nie wybrano rodzaju pojazdu. Wybierz rodzaj pojazdu z listy.";
  }

  if (!values.carBrand) {
    errors.carBrand =
      "Nie wybrano rodzaju pojazdu. Wybierz rodzaj pojazdu z listy.";
  }

  if (!values.carModel) {
    errors.carModel =
      "Nie wybrano rodzaju pojazdu. Wybierz rodzaj pojazdu z listy.";
  }

  if (!values.email) {
    errors.email = "Nie podano adresu e-mail. Proszę podać adres e-mail..";
  } else if (!EmailValidator.validate(values.email)) {
    errors.email =
      "Błędny adres e-mail. Proszę o podanie prawidłowego adresu e-mail.";
  }

  if (!values.nip) {
    errors.nip = "Nie podano numeru NIP. Podaj NIP swojej firmy.";
  } else if (!validatePolish.nip(values.nip) || !(values.nip.length === 10)) {
    errors.nip =
      "Nieprawidłowy nr NIP. Proszę o podanie prawidłowego numeru NIP w formacie 10 cyfr.";
  }

  if (!values.phone) {
    errors.phone = "Nie podano numeru telefonu. Proszę podać numer telefonu.";
  } else if (values.phone.length < 9) {
    errors.phone = "Zbyt krótki numer telefonu.";
  }

  if (!values.priceBrutto) {
    errors.priceBrutto = "Proszę o podanie ceny pojazdu brutto.";
  } else if (values.priceBrutto < 10000 || values.priceBrutto > 1000000) {
    errors.priceBrutto =
      "Minimalna cena samochodu, to 10 000zł, a maksymalna 1 000 000zł brutto. Zmień cenę!";
  }

  if (!values.consentGDPR) {
    errors.consentGDPR = "Zgoda wymagana. Proszę zaznaczyć.";
  }

  if (!values.consentLeasingProcessing) {
    errors.consentLeasingProcessing = "Zgoda wymagana. Proszę zaznaczyć.";
  }

  return errors;
};

const OfferForm = () => {
  const formik = useFormik({
    initialValues: {
      nip: "",
      email: "",
      phone: "",
      priceBrutto: "",
      entryFeePercent: 10,
      purchasePercent: 30,
      leasingTime: 36,
      carType: "",
      carBrand: "",
      carModel: "",
      carYear: new Date().getFullYear(),
      consentGDPR: false,
      consentMarketing: false,
      consentLeasingProcessing: false,
      referer: "porowneo",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validate,
    onSubmit: (values) => {
      values.carYear = parseInt(values.carYear);
      const carType = Cars[values.carType][values.carBrand][
        values.carYear
      ].find((item) => item.name === values.carModel).aliorType;
      const valuesToSend = { ...values, carType };
      setIsLoading(true);
      timeoutOnFetch(requestOffer(valuesToSend))
        .then(async (res) => {
          let data = await res.json();
          if (!res.ok) {
            if (res.status === 422) {
              setErrors([...data.errors]);
            } else {
              setServerError(true);
            }
            setIsLoading(false);
            return;
          }
          await setRequestId(data.id);
        })
        .catch(() => {
          setServerError(true);
        });
    },
  });

  const [requestId, setRequestId] = useState("");
  const [requestData, setRequestData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [serverError, setServerError] = useState(false);
  const currYear = new Date().getFullYear();

  useEffect(() => {
    let sessionRequestData = JSON.parse(sessionStorage.getItem("requestData"));
    if (!sessionRequestData) {
      return;
    }
    sessionRequestData.carType === "SUV"
      ? (sessionRequestData.carType = "osobowe")
      : null;
    setRequestData(sessionRequestData);
  }, []);


  useEffect(() => {
    if (!Object.keys({ ...requestData }).length) {
      return;
    }
    for (const key in requestData) {
      formik.setFieldValue(key, requestData[key]);
    }
    if (formik.values.carType === "SUV") {
      formik.setFieldValue("carType", "osobowe");
    }
    formik.setFieldValue("consentGDPR", false);
    formik.setFieldValue("consentLeasingProcessing", false);
    formik.setFieldValue("consentMarketing", false);
  }, [requestData, formik.setFieldValue]);

  useEffect(() => {
    formik.setFieldValue("carYear", currYear);
  }, [currYear, formik.setFieldValue]);

  useEffect(() => {
    if (!requestId) {
      return;
    }
    let link = `offer?id=${requestId}`;
    navigate(link);
  }, [requestId]);

  return (
    <div>
      {serverError ? <ServerError /> : null}
      <section className="section">
        <div className="container">
          <form onSubmit={(e) => formik.handleSubmit(e)}>
            <div className="columns is-multiline">
              <div className="column is-half">
                <LeasingItemSection
                  handleOnChange={formik.handleChange}
                  carBrand={formik.values.carBrand}
                  carType={formik.values.carType}
                  carYear={formik.values.carYear}
                  carModel={formik.values.carModel}
                  currYear={currYear}
                  errors={formik.errors}
                  setFieldValue={formik.setFieldValue}
                />
              </div>
              <div className="column is-half">
                <LeasingConditionsSection
                  handleOnChange={formik.handleChange}
                  setFieldValue={formik.setFieldValue}
                  priceBrutto={formik.values.priceBrutto}
                  entryFeePercent={formik.values.entryFeePercent}
                  purchasePercent={formik.values.purchasePercent}
                  leasingTime={formik.values.leasingTime}
                  carYear={formik.values.carYear}
                  currYear={currYear}
                  errors={formik.errors}
                  requestData={requestData}
                />
              </div>
              <div className="column is-half">
                <PersonalDataSection
                  handleOnChange={formik.handleChange}
                  companyEmail={formik.values.email}
                  nip={formik.values.nip}
                  companyPhone={formik.values.phone}
                  errors={formik.errors}
                />
                <div className="visible-desktop">
                  <SubmitButton isLoading={isLoading} text="Porównaj oferty" />
                </div>
              </div>
              <div className="column is-half">
                <ConsentsSection
                  consentGDPR={formik.values.consentGDPR}
                  consentMarketing={formik.values.consentMarketing}
                  consentLeasingProcessing={
                    formik.values.consentLeasingProcessing
                  }
                  handleOnChange={formik.handleChange}
                  errors={formik.errors}
                />
                <div className="visible-mobile">
                  <SubmitButton isLoading={isLoading} text="Porównaj oferty" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default OfferForm;
