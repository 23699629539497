import React, { useCallback, useEffect, useState } from "react";

import FormSection from "../../FormComponents/FormSection";
import FormSelect from "../../FormComponents/FormSelect";

import Cars from "../../../data/cars.json";

const carTypeOptions = Object.keys(Cars).map((value, id) => ({ id, value }));

const LeasingItemSection = ({
  handleOnChange,
  carYear,
  carBrand,
  carModel,
  carType,
  currYear,
  errors,
  setFieldValue,
}) => {
  const [yearOptions, setYearOptions] = useState([]);
  const [carBrandOptions, setCarBrandOptions] = useState([]);
  const [carModelOptions, setCarModelOptions] = useState([]);

  const handleOnCarTypeChange = useCallback(
    (e) => {
      setFieldValue("carType", e.target.value);
      setFieldValue("carYear", new Date().getFullYear());
      setFieldValue("carBrand", "");
      setFieldValue("carModel", "");
    },
    [setFieldValue]
  );

  const handleOnCarYearChange = useCallback(
    (e) => {
      setFieldValue("carYear", e.target.value);
      setFieldValue("carBrand", "");
      setFieldValue("carModel", "");
    },
    [setFieldValue]
  );

  const handleOnCarBrandChange = useCallback(
    (e) => {
      setFieldValue("carBrand", e.target.value);
      setFieldValue("carModel", "");
    },
    [setFieldValue]
  );

  useEffect(() => {
    let yearArray = [];
    for (let i = 0; i <= 10; i++) {
      yearArray.push({ id: i + 1, value: currYear - i });
    }
    setYearOptions([...yearArray]);
  }, [currYear, setYearOptions]);

  useEffect(() => {
    if (!carType) {
      setCarBrandOptions([]);
      return;
    }
    const result = Object.keys(Cars[carType]).map((value, id) => ({
      id,
      value,
    }));
    setCarBrandOptions(result);
  }, [carType, setCarBrandOptions]);

  useEffect(() => {
    if (!carBrand) {
      setCarModelOptions([]);
      return;
    }
    const result = Cars[carType][carBrand][carYear].map((value, id) => ({
      id,
      value: value.name,
    }));
    setCarModelOptions(result);
  }, [carBrand, carType, carYear, setCarModelOptions]);

  return (
    <FormSection title="Przedmiot leasingu">
      <FormSelect
        handleChange={handleOnCarTypeChange}
        value={carType}
        name="carType"
        title="Rodzaj pojazdu"
        options={carTypeOptions}
        error={errors.carType}
      />
      <FormSelect
        value={carYear}
        name="carYear"
        handleChange={handleOnCarYearChange}
        options={yearOptions}
        title="Rok produkcji"
      />
      <FormSelect
        handleChange={handleOnCarBrandChange}
        value={carBrand}
        name="carBrand"
        title="Marka"
        options={carBrandOptions}
        error={errors.carBrand}
      />
      <FormSelect
        value={carModel}
        name="carModel"
        handleChange={handleOnChange}
        title="Model"
        options={carModelOptions}
        error={errors.carModel}
      />
    </FormSection>
  );
};

export default LeasingItemSection;
