import React from "react";
import FormSection from "../../FormComponents/FormSection";
import FormInput from "../../FormComponents/FormInput";

const PersonalDataSection = ({
  handleOnChange,
  companyEmail,
  companyPhone,
  nip,
  errors,
}) => (
  <FormSection title="Twoje Dane">
    <FormInput
      value={nip}
      handleChange={handleOnChange}
      name="nip"
      title="NIP"
      error={errors.nip}
    />
    <FormInput
      handleChange={handleOnChange}
      value={companyEmail}
      name="email"
      title="Email"
      error={errors.email}
    />
    <FormInput
      handleChange={handleOnChange}
      value={companyPhone}
      name="phone"
      title="Telefon"
      error={errors.phone}
    />
  </FormSection>
);

export default PersonalDataSection;
