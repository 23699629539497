import React from "react";
import App from "../components/App";
import OfferForm from "../components/OfferForm";
import HeaderText from "../components/Header/HeaderText";

const IndexPage = () => {
  return (
    <App homeSite={true} headerChildren={<HeaderText />}>
      <OfferForm />
    </App>
  );
};

export default IndexPage;
