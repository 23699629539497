import React from "react";

const HeaderText = () => {
  return (
    <div className="hero-body">
      <div className="container center">
        <div className="columns">
          <div className="is-half column">
            <h1 className="is-size-3 has-text-weight-bold has-text-white">
              Kalkulator Leasingowy <br />- do 65% taniej w 2 minuty.
            </h1>
          </div>
          <div className="is-half column">
            <h2 className="is-size-6 has-text-left has-text-black">
              Najlepsza porównywarka leasingowa. Wybierz przedmiot leasingu,
              podaj jego wartość, wpłatę własną, okres leasingu oraz rodzaj
              finansowania. Zobacz najlepsze oferty 7 firm leasingowych
              dopasowane do Twoich potrzeb.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderText;
