import React, { useCallback, useEffect, useState } from "react";
import FormSection from "../../FormComponents/FormSection";
import FormField from "../../FormComponents/FormField";
import FormInput from "../../FormComponents/FormInput";

const LeasingConditionsSection = ({
  handleOnChange,
  setFieldValue,
  priceBrutto,
  entryFeePercent,
  purchasePercent,
  leasingTime,
  carYear,
  currYear,
  errors,
}) => {
  const [leasingMax, setLeasingMax] = useState();
  const [purchaseMax, setPurchaseMax] = useState();
  const [purchaseMin, setPurchaseMin] = useState();

  useEffect(() => {
    let newleasingMax;
    switch (carYear) {
      case `${currYear - 10}`:
        newleasingMax = "24";
        break;
      case `${currYear - 9}`:
        newleasingMax = "36";
        break;
      case `${currYear - 8}`:
        newleasingMax = "48";
        break;
      case `${currYear - 7}`:
        newleasingMax = "60";
        break;
      case `${currYear - 6}`:
        newleasingMax = "72";
        break;
      default:
        newleasingMax = "84";
    }
    setLeasingMax(newleasingMax);
    if (leasingTime > newleasingMax) {
      setFieldValue("leasingTime", newleasingMax);
    }
  }, [carYear, currYear, setLeasingMax, leasingTime, setFieldValue]);

  const handleOnLeasingTimeChange = useCallback(
    (e) => {
      setFieldValue("leasingTime", e.target.value);
    },
    [setFieldValue]
  );

  useEffect(() => {
          let newPurchaseMax;
          let newPurchaseMin = 1;
          switch (parseInt(leasingTime)) {
              case 24:
                  newPurchaseMax = 40;
                  newPurchaseMin = 19;
                  break;
              case 36:
                  newPurchaseMax = 30;
                  break;
              case 48:
                  newPurchaseMax = 25;
                  break;
              case 60:
                  newPurchaseMax = 20;
                  break;
              default:
                  newPurchaseMax = 1;
          }
          setPurchaseMax(newPurchaseMax);
          setPurchaseMin(newPurchaseMin);
          if (
              purchasePercent >= newPurchaseMin &&
              purchasePercent <= newPurchaseMax
          ) {
              return;
          }
          let newPurchasePercent;
          if (purchasePercent < newPurchaseMin) {
              newPurchasePercent = newPurchaseMin;
          } else if (purchasePercent > newPurchaseMax) {
              newPurchasePercent = newPurchaseMax;
          }
          setFieldValue("purchasePercent", newPurchasePercent)
      }, [leasingTime, purchasePercent, setFieldValue, setPurchaseMax, setPurchaseMin]);

  return (
    <FormSection title={"Warunki leasingu"}>
      <FormInput
        handleChange={handleOnChange}
        value={priceBrutto}
        name="priceBrutto"
        title="Cena brutto"
        type="number"
        error={errors.priceBrutto}
      />
      <FormField title="Okres w mies.">
        <div className="columns is-vcentered">
          <div className="column is-10">
            <input
              className="leasingpoint-range"
              step="12"
              min="24"
              max={leasingMax}
              onChange={handleOnLeasingTimeChange}
              value={leasingTime}
              name="leasingTime"
              type="range"
            />
          </div>
          <span className="column is-2">{leasingTime}&nbsp;</span>
        </div>
      </FormField>
      <FormField title="Opłata wstępna">
        <div className="columns is-vcentered">
          <div className="column is-10">
            <input
              className="leasingpoint-range"
              step="1"
              min="1"
              max="45"
              onChange={(e) => handleOnChange(e)}
              value={entryFeePercent}
              name="entryFeePercent"
              type="range"
            />
          </div>
          <span className="column is-2">{entryFeePercent}%</span>
        </div>
      </FormField>
      <FormField title="Wykup">
        <div className="columns is-vcentered">
          <div className="column is-10">
            <input
              className="leasingpoint-range"
              step="1"
              min={purchaseMin}
              max={purchaseMax}
              onChange={(e) => handleOnChange(e)}
              value={purchasePercent}
              name="purchasePercent"
              type="range"
            />
          </div>
          <span className="column is-2">{purchasePercent}%</span>
        </div>
      </FormField>
    </FormSection>
  );
};

export default LeasingConditionsSection;
